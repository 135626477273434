import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { MainPicture } from '../../components/MainPicture';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { Seo } from '../../components/Seo';
import { colors, device } from '../../components/layout/GlobalStyles';

const StyledHygienists = styled.div`
    padding: 2em 1em;
    .doctor {
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
            margin: 1em 0em;
            font-size: 24px;
            color: ${colors.blueDark};
            font-weight: 700;
        }
        .doctor-pic {
            width: 305px;
            height: 371px;
        }
        p {
            margin: 1em 0em;
        }
    }
    @media ${device.tablet} {
        padding-top: 0em;
        .doctor {
            .text {
                width: 500px;
                margin-top: 4em;
            }
            .doctor-pic {
                width: 305px;
                height: 371px;
                margin-top: 6em;
            }
            flex-direction: row;
            max-width: 1100px;
            margin: 0 auto;
            align-items: flex-start;
            justify-content: space-between;
        }
        .rev {
            flex-direction: row-reverse;
        }
    }
    @media ${device.laptopL} {
        .doctor {
            .text {
                width: 730px;
            }
        }
    }
`;

const Hygienists: Page = () => (
    <DefaultLayout currentPage="Meet The Hygienists">
        <Seo
            title="Meet Magic Smiles Hygienists "
            description="Meet our hygienists at your local family dentistry in Linden New Jersey"
        />
        <StyledHygienists>
            <MainPicture title="Our Hygienists">
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    placeholder="none"
                    className="underlayer"
                    src="../../images/homepage-team-cropped.jpeg"
                    alt="staff smiling together"
                />
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/homepage-team-cropped.jpeg"
                    alt="staff smiling together"
                />
            </MainPicture>
            <div className="rev doctor">
                <StaticImage
                    className="doctor-pic"
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/staff/miguel.jpeg"
                    alt="Dr. Bensianoff"
                />
                <div className="text">
                    <h4>Miguel</h4>
                    <h5>Registered Dental Hygenist</h5>
                    <p>
                       
                    </p>
                    
                </div>
            </div>

            <div className="doctor">
                <StaticImage
                    className="doctor-pic"
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/doctors/registered-new.jpg"
                    alt="Dr. Yakubov"
                />
                <div className="text">
                    <h4>Jackie</h4>
                    <h5>Registered Dental Hygienist</h5>
                    <p>
                         
                    </p>
                </div>
            </div>
        </StyledHygienists>
    </DefaultLayout>
);

export default Hygienists;
